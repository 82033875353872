<template>
  <iq-card body-class="p-0">
    <template v-slot:body>
      <div class="user-post-data p-3">
        <div class="d-flex flex-wrap">
          
          <div class="media-support-user-img mr-3">
            <b-img v-if='post.user && post.user.profile' rounded="circle" fluid :src="post.user.profile" alt="user"/>
            <b-img v-else  src="../../../../assets/images/user-icon.svg" rounded="circle" fluid alt="user"/>
          </div>
          <div class="media-support-info mt-2">
            <router-link v-if='post.user' :to="{ name: 'user.profile', params: { userId: post.user.id }}">
            <h5 class="mb-0"><b-link href="javascript:void(0)" class="">{{post.user.name}}</b-link></h5>
            <!-- <p class="mb-0 text-secondary">{{post.time | formatDate }}</p> -->
            <p class="mb-0 text-secondary">{{post.time  }}</p>
            </router-link>
          </div>
          
        </div>
      </div>
    </template>
    <hr class="m-0" />
    <div class="user-post">
      <h4 class="p-2" >{{post.caption}}</h4>
     
      <!-- <p class="p-2" v-if="post.description">{{post.description}}</p> -->
      <p class="p-2" v-if="post.musicLink">Link : <a :href='post.musicLink'>{{ post.musicLink }}</a></p>
      <div id="photo-grid">
        <span v-if='post.images'>
        <photo-grid :box-height="'400px'" :box-width="'100%'" :boxBorder=2>
          <img v-for="(image, index) in post.images" :src="image" :key="index" @click="showMultiple" style="cursor:pointer"  />
        </photo-grid>
        </span>
      </div>
       <div class="p-2" v-html="post.description" />
    </div>
  <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="post.images"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
</iq-card>
</template>
<script>
import axios from '../../../../components/axios'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'SocialNews',
  components: { VueEasyLightbox},
  props: [
    'post'
  ],
  data () {
    return {
      commentMessage: '',
      is_like:'',
      is_commentLike:'',
      visible: false,
      index: 0 // default: 0
    }
  },
  mounted() {
axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");
  },
  methods: {
 
    isFollowed (follow) {
      this.post.is_follow = follow
    },
    showMultiple() {
       
        // allow mixing

        this.index = 1 // index of imgList
        this.show()
      },
      show() {
        this.visible = true
      },
      handleHide() {
        this.visible = false
      },
    
  }
}
</script>

<style>
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover{
    background: transparent;
    color: var(--iq-primary) !important;
  }
</style>
